import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 32px 20px 32px;
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.purple40};
    margin-bottom: 0px;
  }

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: ${Colors.black70};
    margin-top: 6px;
  }

  img {
    width: 502px;
    height: 252.66px;
    border-radius: 10px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  div.empty {
    width: 502px;
    height: 252.66px;
    background: ${Colors.background6};
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;

      color: ${Colors.black3};
    }

    @media (max-width: 600px) {
      width: 100%;

      h3 {
        font-size: 12px;
      }

      svg {
        height: 50px;
        width: 50px;
      }
    }
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-bottom: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
  padding: 10px 32px 20px 32px;
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  margin-top: 20px;

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      /* identical to box height, or 32px */

      /* Secundaria/secundaria 70 */

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  /* @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  } */
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 49%;
  display: flex;
  flex-direction: column;

  height: 68px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 20%; */
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    line-height: 160%;
    padding: 0px 0px 4px 4px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 12px;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    /* margin-top: 10px; */
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

interface ButtonEditProps {
  isLoading?: boolean;
}

export const ButtonEdit = styled.button<ButtonEditProps>`
  margin-top: 24px;

  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.primary100};
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;
