import { useEffect, useRef, useState } from "react";
import packageJson from "../../../package.json";

import { DatePicker, Select } from "antd";

import { Sidebar } from "components/Sidebar";

import {
  AccountDropDownOrganizer,
  ContainerFiltersMobile,
  ContainerInput,
  CustomFilter,
  DivGraph,
  GraphLast,
  ListButtons,
  TitleGraphMobile,
  Wrapper,
} from "./styles";

import {
  Container,
  ContainerItems,
  Card,
  Button,
  SidebarFilters,
  ContainerResults,
  CardResult,
  ContainerGraphs,
  Graph,
  TitleGraph,
  CardResultLoading,
} from "./styles";

import up from "../../assets/up.svg";
import { Banners } from "./components/Banners";

import Chart from "react-apexcharts";
import { BannersCategory } from "./components/BannersCategory";
import { Header } from "components/Header";
import Skeleton from "react-loading-skeleton";
import { BsPlus } from "react-icons/bs";
import { Colors } from "constants/colors";

import { MdKeyboardArrowRight } from "react-icons/md";
import { AiFillCaretDown } from "react-icons/ai";

import { useAuthTotals } from "services/hooks/dashboard";
import { useAuthTicketCharts } from "services/hooks/dashboard";
import { useAuthPurchaseCharts } from "services/hooks/dashboard";

import { PurchaseCharts, TicketCharts, TotalType } from "types";

import { HighlightedEvents } from "./components/HighlightedEvents";
import { LastEvents } from "./components/LastEvents";

import user_result_icon from "../../assets/user-result-icon.svg";
import { useGetDateAgo } from "hooks/useGetDateAgo";
import { paymentsTypePercentageFormat } from "utils/formatKeys";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

export default function Home() {
  const refOne = useRef<HTMLDivElement>(null);

  const { daysFilter } = useGetDateAgo();

  const [activeDropOrganizer, setActiveDropOrganizer] = useState(false);

  const [seriesHere, setSeries] = useState<any>();

  const [filterActive, setFilterActive] = useState(0);
  const [finalFilter, setFinalFilter] = useState(
    `created_gte=${daysFilter[0]?.date_start}&created_lte=${daysFilter[0]?.date_end}`
  );
  const [dataGte, setDataGte] = useState<string | null>(null);
  const [dataLte, setDataLte] = useState<string | null>(null);

  const [filterObject, setFilterObject] = useState({
    created_gte: daysFilter[0]?.date_start,
    created_lte: daysFilter[0]?.date_end,
  });

  const { data: dataTotals } = useAuthTotals<TotalType>({
    filter:
      dataGte && dataLte
        ? `created_gte=${dataGte}&created_lte=${dataLte}`
        : finalFilter,
  });

  const created_gte = filterObject.created_gte;
  const created_lte = filterObject.created_lte;

  const { data: dataTicketsGraph } = useAuthTicketCharts<TicketCharts>({
    created_gte: dataGte && dataLte ? dataGte : created_gte,
    created_lte: dataGte && dataLte ? dataLte : created_lte,
  });

  const { data: dataPurchaseGraph } = useAuthPurchaseCharts<PurchaseCharts>({
    created_gte: dataGte && dataLte ? dataGte : created_gte,
    created_lte: dataGte && dataLte ? dataLte : created_lte,
  });

  const [isModalBannerVisible, setIsModalBannerVisible] = useState(false);

  const showModalBanner = () => {
    setIsModalBannerVisible(true);
    setActiveDropOrganizer(false);
  };

  const [isModalHighVisible, setIsModalHighVisible] = useState(false);

  const showModalHigh = () => {
    setIsModalHighVisible(true);
    setActiveDropOrganizer(false);
  };

  const [isModalCategoryVisible, setIsModalCategoryVisible] = useState(false);

  const showModalCategory = () => {
    setIsModalCategoryVisible(true);
    setActiveDropOrganizer(false);
  };

  useEffect(() => {
    if (dataPurchaseGraph) {
      setSeries(dataPurchaseGraph);
    }
  }, [dataPurchaseGraph]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (!refOne.current?.contains(e.target)) {
      setActiveDropOrganizer(false);
    } else {
      setActiveDropOrganizer(true);
    }
  };

  const colors = ["#D9C25C", "#863B66", "#005284", "#228E52"];

  var options = {
    series: [
      {
        data: [21, 22, 10, 28],
      },
    ],
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dataTicketsGraph?.mostSoldEventTickets?.map(
        (ticket) =>
          `${ticket?.event_name?.substring(
            0,
            11
          )} - ${ticket.event_ticket_name?.substring(0, 42)}`
      ),
      labels: {
        rotate: -45,
        style: {
          fontSize: "10px",
        },
        maxHeight: 290,
      },
    },
  };

  const series = [
    {
      name: "series1",
      data: dataTicketsGraph?.mostSoldEventTickets?.map(
        (ticket) => ticket.ticket_count
      ),
    },
  ];

  const seriesMostSold =
    dataPurchaseGraph?.paymentsTypePercentage.map(
      (purchase) => purchase.percentage
    ) ?? [];

  const labelsMostSold =
    dataPurchaseGraph?.paymentsTypePercentage.map((purchase) =>
      paymentsTypePercentageFormat(purchase.payment_type)
    ) ?? [];

  const optionsMostSold: any = {
    chart: {
      width: 980,
      height: 600,
      type: "donut",
    },
    labels: labelsMostSold,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
            },
            value: {
              show: true,
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: string) {
          return value + "%";
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: number) {
        return value.toFixed(1) + "%";
      },
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 240,
            height: 300,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  };

  const handleChangeFilterActive = async (index: number) => {
    if (index === filterActive) {
      return;
    }

    if (index !== 4) {
      setDataGte("");
      setDataLte("");
    }

    if (index === 4) {
      setFilterActive(index);

      setFinalFilter("");

      setFilterObject({
        created_gte: "",
        created_lte: "",
      });

      return;
    }

    setFinalFilter(
      `created_gte=${daysFilter[index]?.date_start}&created_lte=${daysFilter[index]?.date_end}`
    );

    setFilterObject({
      created_gte: daysFilter[index]?.date_start,
      created_lte: daysFilter[index]?.date_end,
    });

    setFilterActive(index);
  };

  const handleSelectFilterActive = (value: any) => {
    setFilterActive(value.value);

    if (value.value !== 4) {
      setDataGte("");
      setDataLte("");
    }

    if (value.value === 4) {
      setFilterActive(value.value);

      setFinalFilter("");

      setFilterObject({
        created_gte: "",
        created_lte: "",
      });

      return;
    }

    setFinalFilter(
      `created_gte=${daysFilter[value.value]?.date_start}&created_lte=${
        daysFilter[value.value]?.date_end
      }`
    );

    setFilterObject({
      created_gte: daysFilter[value.value]?.date_start,
      created_lte: daysFilter[value.value]?.date_end,
    });
  };

  function onChangeOne(date: any, dateString: any) {
    if (!dateString) {
      setDataGte("");

      return;
    }

    const newDate: string = dateString.split("/");

    const dateComponent = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    setDataGte(dateComponent);
  }

  function onChangeTwo(date: any, dateString: any) {
    if (!dateString) {
      setDataLte("");

      return;
    }

    const newDate: string = dateString.split("/");

    const dateComponent = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    setDataLte(dateComponent);
  }

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <h1>
            Dashboard{" "}
            <span>
              v{packageJson.version} - {process.env.REACT_APP_AMBIENT}
            </span>
          </h1>

          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons>
            <div ref={refOne}>
              <Button onClick={() => setActiveDropOrganizer(true)}>
                <BsPlus color={Colors.white} size={23} />
                <span>Novo</span>

                <div className="icon">
                  <AiFillCaretDown color={Colors.white} size={12} />
                </div>
              </Button>

              {activeDropOrganizer ? (
                <AccountDropDownOrganizer>
                  <ul>
                    <li onClick={showModalBanner}>
                      <h5>Slider</h5>
                    </li>

                    <li className="last" onClick={showModalHigh}>
                      <h5>Eventos em destaque</h5>
                    </li>
                  </ul>
                </AccountDropDownOrganizer>
              ) : null}
            </div>
          </ListButtons>

          <Card>
            <SidebarFilters>
              <div
                className={`card-filter ${filterActive === 0 ? "active" : ""}`}
                onClick={() => handleChangeFilterActive(0)}
              >
                <span className={filterActive === 0 ? "active" : ""}>Hoje</span>

                <MdKeyboardArrowRight size={16} color={Colors.secondary80} />
              </div>
              <div
                className={`card-filter ${filterActive === 1 ? "active" : ""}`}
                onClick={() => handleChangeFilterActive(1)}
              >
                <span className={filterActive === 1 ? "active" : ""}>
                  Esta semana
                </span>

                <MdKeyboardArrowRight size={16} color={Colors.secondary80} />
              </div>
              <div
                className={`card-filter ${filterActive === 2 ? "active" : ""}`}
                onClick={() => handleChangeFilterActive(2)}
              >
                <span className={filterActive === 2 ? "active" : ""}>
                  Esse mês
                </span>

                <MdKeyboardArrowRight size={16} color={Colors.secondary80} />
              </div>
              <div
                className={`card-filter ${filterActive === 3 ? "active" : ""}`}
                onClick={() => handleChangeFilterActive(3)}
              >
                <span className={filterActive === 3 ? "active" : ""}>
                  Últimos 5 meses
                </span>

                <MdKeyboardArrowRight size={16} color={Colors.secondary80} />
              </div>
              <CustomFilter
                active={filterActive === 4}
                className={`card-filter-custom ${
                  filterActive === 4 ? "active" : ""
                }`}
                onClick={() => handleChangeFilterActive(4)}
              >
                <div className="content">
                  <span className={filterActive === 4 ? "active" : ""}>
                    Personalizado
                  </span>

                  <MdKeyboardArrowRight size={16} color={Colors.secondary80} />
                </div>

                {filterActive === 4 ? (
                  <div className="time-course">
                    <ContainerInput>
                      <strong>Período</strong>

                      <div className="range-pick">
                        <DatePicker
                          format={dateFormat}
                          placeholder="00/00/0000"
                          style={{
                            width: "48%",

                            padding: 0,
                            paddingLeft: 2,
                          }}
                          bordered={false}
                          suffixIcon={null}
                          onChange={onChangeOne}
                        />
                        <div className="dot">-</div>
                        <DatePicker
                          format={dateFormat}
                          placeholder="00/00/0000"
                          style={{
                            width: "48%",
                            paddingRight: "0px",
                          }}
                          bordered={false}
                          suffixIcon={null}
                          onChange={onChangeTwo}
                        />
                      </div>
                    </ContainerInput>
                  </div>
                ) : null}
              </CustomFilter>
            </SidebarFilters>

            <ContainerResults>
              {!dataTotals
                ? [0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
                    <CardResultLoading>
                      <div className="first-information">
                        <div className="content">
                          <Skeleton circle={true} height={24} width={24} />

                          <Skeleton style={{ marginLeft: 10 }} width={80} />
                        </div>

                        <Skeleton circle={true} height={14} width={14} />
                      </div>

                      <div className="second-information">
                        <div className="column">
                          <Skeleton height={30} width={60} />
                          <Skeleton
                            style={{ marginTop: 5, marginBottom: 10 }}
                            width={55}
                          />
                        </div>

                        <Skeleton
                          height={10}
                          width={15}
                          style={{ marginBottom: 22 }}
                        />
                      </div>
                    </CardResultLoading>
                  ))
                : null}

              {dataTotals?.results?.map((report) => (
                <CardResult>
                  <div className="first-information">
                    <div className="content">
                      <div className="circle">
                        <img src={report.icon ?? user_result_icon} alt="" />
                      </div>
                      <h3>{report.name}</h3>
                    </div>

                    <img src={up} alt="" />
                  </div>

                  <div className="second-information">
                    <div className="column">
                      <h4 className="format">{report?.total}</h4>

                      {report.name !== "Total vendas" &&
                      report.name !== "Total Eventx" ? (
                        <span className="available">DISPONÍVEIS</span>
                      ) : null}
                    </div>
                  </div>
                </CardResult>
              ))}
            </ContainerResults>
          </Card>

          <ContainerFiltersMobile>
            <h3>Período</h3>

            <div className="container-select">
              <div className="select">
                <Select
                  labelInValue
                  defaultValue={0}
                  onChange={handleSelectFilterActive}
                  style={{ width: "100%" }}
                >
                  {daysFilter?.map((data, index) => (
                    <Option value={index} key={data.id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              </div>

              {filterActive === 4 ? (
                <div className="time-course">
                  <ContainerInput>
                    <div className="range-pick">
                      <DatePicker
                        format={dateFormat}
                        placeholder="00/00/0000"
                        style={{
                          width: "48%",

                          padding: 0,
                          paddingLeft: 2,
                        }}
                        bordered={false}
                        suffixIcon={null}
                        onChange={onChangeOne}
                      />
                      <div className="dot">-</div>
                      <DatePicker
                        format={dateFormat}
                        placeholder="00/00/0000"
                        style={{
                          width: "48%",
                          paddingRight: "0px",
                        }}
                        bordered={false}
                        suffixIcon={null}
                        onChange={onChangeTwo}
                      />
                    </div>
                  </ContainerInput>
                </div>
              ) : null}
            </div>
          </ContainerFiltersMobile>

          <ContainerGraphs>
            <DivGraph>
              <TitleGraphMobile>Ingressos mais vendidos</TitleGraphMobile>

              <Graph>
                <TitleGraph>Ingressos mais vendidos</TitleGraph>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height={390}
                />
              </Graph>
            </DivGraph>

            <DivGraph>
              <TitleGraphMobile>
                Formas de pagamento mais usadas
              </TitleGraphMobile>

              <GraphLast>
                <TitleGraph>Formas de pagamento mais usadas</TitleGraph>
                {seriesHere ? (
                  <Chart
                    options={optionsMostSold}
                    series={seriesMostSold}
                    type="donut"
                    width={450}
                  />
                ) : null}
              </GraphLast>
            </DivGraph>
          </ContainerGraphs>

          <div style={{ marginTop: 40 }}></div>

          <Banners
            title="Slider"
            slug="slider"
            showModal={showModalBanner}
            isModalVisible={isModalBannerVisible}
            setIsModalVisible={setIsModalBannerVisible}
          />

          <HighlightedEvents
            title="Highlight"
            slug="highlight"
            showModal={showModalHigh}
            isModalVisible={isModalHighVisible}
            setIsModalVisible={setIsModalHighVisible}
          />

          <BannersCategory
            title="Categorias"
            slug="categoria"
            showModal={showModalCategory}
            isModalVisible={isModalCategoryVisible}
            setIsModalVisible={setIsModalCategoryVisible}
          />

          <LastEvents
            title="Últimos eventos"
            slug="last"
            showModal={showModalHigh}
            isModalVisible={isModalHighVisible}
            setIsModalVisible={setIsModalHighVisible}
          />
        </ContainerItems>
      </Container>
    </Wrapper>
  );
}
