export const returnStatusPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "Pago";
    case "PENDING":
    default:
      return "Pendente";
    case "REFUND":
      return "Estornado";
  }
};

export const returnStatusColorPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "green";
    case "PENDING ":
      return "orange";
    case "REFUND":
      return "red";

    default:
      return "yellow";
  }
};

export const returnTypePaymentPurchase = (value: string) => {
  switch (value) {
    case "credit-card":
      return "Cartão de Crédito";

    case "boleto":
      return "Boleto";

    case "courtesy":
      return "Cortesia";

    case "pix":
      return "Pix";

    default:
      return "Não informado";
  }
};

export const returnTypePurchase = (value: string = "") => {
  switch (value) {
    case "eventx-checkout":
      return "Checkout EventX";
    case "import":
      return "Importação";
    case "xgrow":
      return "Xgrow";
    case "manual":
      return "Venda Manual";
    default:
      return "Não informado";
  }
};
