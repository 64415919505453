import { LinkWithHover } from "styles/LinkWithHover";
import { Event } from "pages/ticket/styles";
import { WithdrawItem } from "types";
import { StatusComponent } from "components/Status";
import { formatPrice } from "utils/formatPrice";
import moment from "moment";
import { CardContainer } from "./styles";

interface DetailWithdrawProps {
  withdraw: WithdrawItem;
}

function DetailWithdraw({ withdraw }: DetailWithdrawProps) {
  const handleRenderStatus = (status: WithdrawItem["status"]) => {
    switch (status) {
      case "pending":
      default:
        return (
          <StatusComponent color="yellow" content="Pendente" size="small" />
        );
      case "canceled":
        return <StatusComponent color="red" content="Cancelado" size="small" />;
      case "process":
        return (
          <StatusComponent
            color="darkBlue"
            content="Processando"
            size="small"
          />
        );
      case "paid":
        return (
          <StatusComponent color="green" content="Aprovado" size="small" />
        );
    }
  };

  return (
    <CardContainer>
      <div className="column">
        <head>
          <div>
            <LinkWithHover to={`/withdraw/${withdraw?._id}`}>
              <h4>
                <span>ID do saque:</span> 123
              </h4>
            </LinkWithHover>

            <LinkWithHover to={`/organizers/${withdraw?.organizer?.id ?? ""}`}>
              <h4>
                <span>Nome:</span>{" "}
                {withdraw?.organizer?.name ?? "Não informado"}
              </h4>
            </LinkWithHover>

            <h4>
              <span>Antecipação?</span> {withdraw?.antecipated ? "Sim" : "Não"}
            </h4>

            <h4>
              <span>Valor total:</span> {formatPrice(withdraw.total / 10)}
            </h4>

            <h4>
              <span>Taxas:</span> {formatPrice(withdraw.total_fee / 10)}
            </h4>

            <h4>
              <span>Valor líquido:</span> {formatPrice(withdraw.total_wet / 10)}
            </h4>

            <h4>
              <span>Solicitado em:</span>{" "}
              {moment(withdraw.created_at).format("DD/MM/YYYY HH:mm")}
            </h4>

            <h4>
              <span>Atualizado em:</span>{" "}
              {moment(withdraw.updated_at).format("DD/MM/YYYY HH:mm")}
            </h4>
          </div>

          <div className="options">{handleRenderStatus(withdraw?.status)}</div>
        </head>
      </div>
    </CardContainer>
  );
}

export default DetailWithdraw;
