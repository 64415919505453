import moment from "moment";
import { authClient } from "services/authClient";
import useSWR from "swr";
import {
  EventUserType,
  PaginatedResponse,
  WithdrawalResponse,
  WithdrawResponse,
} from "types";

import { OrganizerType } from "types";

interface AddOrganizerBankProps {
  idOrganizer: string;
  credentials: any;
}

export async function AddOrganizerBank({
  idOrganizer,
  credentials,
}: AddOrganizerBankProps) {
  const url = `/backoffice/organizer/add-bank/${idOrganizer}`;

  await authClient.post(url, credentials);
}

interface EditOrganizerProps {
  idOrganizer: string;
  credentials: any;
}

export async function EditOrganizer({
  idOrganizer,
  credentials,
}: EditOrganizerProps) {
  const url = `/backoffice/organizer/${idOrganizer}`;

  await authClient.patch(url, credentials);
}

interface EditOrganizerBankProps {
  bankId: number | undefined;
  credentials: any;
}

export async function EditOrganizerBank({
  bankId,
  credentials,
}: EditOrganizerBankProps) {
  const url = `/backoffice/organizer-bank/edit/${bankId}`;

  await authClient.patch(url, credentials);
}

interface UseAuthEventsOrganizerProps {
  organizerId: number | null;
  page: string | number;
}

export function useAuthEventsOrganizer<Data = any, Error = any>({
  organizerId,
  page,
}: UseAuthEventsOrganizerProps) {
  const url = `/backoffice/event/filter?organizer=${organizerId}&page=${
    page ?? 1
  }&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((event: EventUserType) => {
      const date = moment(event.begin_date);
      const dateComponent = date.utc().format("DD/MM/YYYY");

      return {
        ...event,
        formattedBeginDate: dateComponent,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export async function SendDocumentOrganizer(formData: FormData) {
  const url = `/backoffice/organizer/send-document`;

  const response = await authClient.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
}

export function useAuthOrganizer<Data = any, Error = any>(id: string) {
  const url = `/backoffice/organizer/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const organizer = response.data;

    let dateComponent = "";

    if (organizer.agent_birthdate) {
      const date = moment(organizer.agent_birthdate);
      dateComponent = date.utc().format("DD/MM/YYYY");
    }

    const newResponse = {
      ...organizer,
      formattedAgentBirthdate: dateComponent,
    };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthOrganizersProps {
  page?: string | number;
  filters?: any;
}

export function useAuthOrganizers<Data = any, Error = any>({
  page,
  filters,
}: useAuthOrganizersProps) {
  // const url = `/backoffice/organizer?page=${page ?? 1}&limit=20${
  //   filters ? `&${filters}` : null
  // }`;

  const url = `/backoffice/organizer/filter?page=${page ?? 1}&${filters}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((organizer: OrganizerType) => {
      let dateComponent = "";
      let eventsActive = 0;

      // if (organizer.agent_birthdate) {
      //   const date = moment(organizer.agent_birthdate);
      //   dateComponent = date.utc().format("DD/MM/YYYY");
      // }

      // if (organizer.events.length > 0) {
      //   eventsActive = organizer.events.reduce(
      //     (
      //       acc: number,
      //       att: {
      //         status: boolean;
      //       }
      //     ) => {
      //       return att.status ? acc + 1 : acc;
      //     },
      //     0
      //   );
      // }

      return {
        ...organizer,
        eventsActive: 0,
        formattedAgentBirthdate: "",
        isOpen: false,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

type OrganizersByUserIdResponse = PaginatedResponse<OrganizerType>;

export function useAuthOrganizersById(userId: string) {
  const url = `/backoffice/organizer/filter?limit=100&user=${userId}&page=1`;

  return useSWR(url, async () => {
    const { data } = await authClient.get<OrganizersByUserIdResponse>(url);

    return data;
  });
}

interface UseAuthOrganizerWithdraw {
  page?: string | number;
  filters?: string;
}

export function useAuthOrganizerWithdraw({
  page,
  filters,
}: UseAuthOrganizerWithdraw) {
  const url = `/backoffice/organizer-withdraw/filter?page=${
    page ?? 1
  }&limit=20${filters && `&${filters}`}`;

  return useSWR(url, async () => {
    const { data } = await authClient.get<WithdrawalResponse>(url);

    return data;
  });
}

export function handleApproveWithdraw(id: string) {
  const url = `/backoffice/organizer/withdraw/approve`;

  return authClient.post(url, {
    withdraw_id: id,
  });
}

export function handleRejectWithdraw(id: string, status_detail: string) {
  const url = `/backoffice/organizer/withdraw/reject`;

  return authClient.post(url, {
    withdraw_id: id,
    status_detail,
  });
}
