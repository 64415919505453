import { Avatar, Container, ContainerItems, Profile } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

import notification from "../../assets/notification.svg";
import dashIcon from "../../assets/dashIcon.svg";
import usersIcon from "../../assets/usersIcon.svg";
import organizerIcon from "../../assets/organizerIcon.svg";
import ticketsIcon from "../../assets/ticketsIcon.svg";
import eventsIcon from "../../assets/eventsIcon.svg";
import vendasIcon from "../../assets/vendasIcon.svg";
import faqIcon from "../../assets/faqIcon.svg";
import suporteIcon from "../../assets/suporteIcon.svg";
import visualIcon from "../../assets/visualIcon.svg";
import opcoesIcon from "../../assets/opcoesIcon.svg";
import exitIcon from "../../assets/exitIcon.svg";
import { useAuth } from "contexts/AuthContext";

import user_avatar from "assets/user-avatar.svg";

export function SidebarMobile() {
  const navigate = useNavigate();

  const { signOut, user } = useAuth();

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const changeRouter = (route: string) => {
    navigate(route);
  };

  return (
    <Container>
      <ContainerItems>
        <ul>
          {/* <nav className="first">
            <div className="notification">
              <div className="circle">
                <img src={notification} alt="" />
              </div>

              {false ? <span className="badge">5</span> : null}
            </div>
            <h2>Notificações</h2>
          </nav> */}

          <nav
            style={{ marginTop: 25 }}
            className={`card ${splitLocation[1] === "" ? "active" : ""}`}
            onClick={() => changeRouter("/")}
          >
            <img src={dashIcon} alt="" />
            <h2>Dashboard</h2>
          </nav>

          <nav
            className={`card ${splitLocation[1] === "users" ? "active" : ""}`}
            onClick={() => changeRouter("/users")}
          >
            <img src={usersIcon} alt="" />
            <h2>Usuários</h2>
          </nav>

          <nav
            className={`card ${
              splitLocation[1] === "organizers" ? "active" : ""
            }`}
            onClick={() => changeRouter("/organizers")}
          >
            <img src={organizerIcon} alt="" />
            <h2>Organizadores</h2>
          </nav>

          <nav
            className={`card ${
              splitLocation[1] === "withdrawal" ? "active" : ""
            }`}
            onClick={() => changeRouter("/withdrawal")}
          >
            <img src={vendasIcon} alt="" />
            <h2>Saques</h2>
          </nav>

          <nav
            className={`card ${splitLocation[1] === "events" ? "active" : ""}`}
            onClick={() => changeRouter("/events")}
          >
            <img src={eventsIcon} alt="" />
            <h2>Eventos</h2>
          </nav>

          <nav
            className={`card ${splitLocation[1] === "tickets" ? "active" : ""}`}
            onClick={() => changeRouter("/tickets")}
          >
            <img src={ticketsIcon} alt="" />
            <h2>Ingressos</h2>
          </nav>

          <nav
            className={`card ${splitLocation[1] === "sales" ? "active" : ""}`}
            onClick={() => changeRouter("/sales")}
          >
            <img src={vendasIcon} alt="" />
            <h2>Vendas</h2>
          </nav>

          <nav
            className={`card ${splitLocation[1] === "faq" ? "active" : ""}`}
            onClick={() => changeRouter("/faq")}
          >
            <img src={faqIcon} alt="" />
            <h2>FAQ</h2>
          </nav>

          {/* <nav
            className={`card ${
              splitLocation[1] === "messages" ? "active" : ""
            }`}
            onClick={() => changeRouter("/messages")}
          >
            <img src={suporteIcon} alt="" />
            <h2>Suporte</h2>
          </nav> */}

          <nav
            className={`card ${splitLocation[1] === "visual" ? "active" : ""}`}
            onClick={() => changeRouter("/visual")}
          >
            <img src={visualIcon} alt="" />
            <h2>Visual</h2>
          </nav>
        </ul>

        <Profile>
          <Avatar src={user?.avatar ?? user_avatar} alt={user?.name} />

          <div className="content">
            <div className="first">
              <h3>{user?.name}</h3>
              <p>{user?.email}</p>
            </div>

            <div className="second">
              <div className="icon">
                <img src={opcoesIcon} alt="" />
                <span>Opções</span>
              </div>

              <div className="icon" onClick={signOut}>
                <img src={exitIcon} alt="" />
                <span>Sair</span>
              </div>
            </div>
          </div>
        </Profile>
      </ContainerItems>
    </Container>
  );
}
