import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GoChevronRight } from "react-icons/go";
import { Tabs } from "antd";
import { Sidebar } from "components/Sidebar";
import { Header } from "components/Header";
import { WithdrawItem } from "types";
import { Colors } from "constants/colors";
import {
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  ContainerForm,
} from "./styles";
import { Wrapper } from "./styles";
import DetailWithdraw from "./components/DetailWithdraw";
import { useAuthOrganizerWithdraw } from "services/hooks/organizer";
import { EmptyDetail } from "components/EmptyDetail";
import WithdrawApproval from "./components/WithdrawApproval";

const { TabPane } = Tabs;

function WithdrawPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusHasChanged, setStatusHasChanged] = useState(false);
  const [key, setKey] = useState("1");

  const { data } = useAuthOrganizerWithdraw({
    filters: `&_id=${id}`,
  });

  const withdraw = data?.results[0] as WithdrawItem;

  const tabKey = searchParams.get("tab_key") ?? "";

  useEffect(() => {
    if (tabKey) {
      setKey(tabKey);
    }
  }, [tabKey]);

  const handleTabClick = (key: string) => {
    setKey(key);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tab_key: key,
    });
  };

  const handleStatusChanged = () => {
    setStatusHasChanged(true);
    setKey("1");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Saques</h1>

            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">Saques</h3>

            {withdraw ? (
              <Tabs
                defaultActiveKey="1"
                type="card"
                size={"small"}
                onChange={handleTabClick}
                activeKey={key}
                key={key}
              >
                <TabPane tab="Detalhes" key="1">
                  <ContainerForm>
                    {withdraw ? (
                      <DetailWithdraw
                        withdraw={data?.results[0] as WithdrawItem}
                      />
                    ) : null}
                  </ContainerForm>
                </TabPane>
                {withdraw?.status === "pending" && !statusHasChanged && (
                  <TabPane tab="Aprovação" key="2">
                    <ContainerForm>
                      <WithdrawApproval
                        withdrawId={id ?? ""}
                        onStatusChange={handleStatusChanged}
                      />
                    </ContainerForm>
                  </TabPane>
                )}
              </Tabs>
            ) : (
              <EmptyDetail />
            )}
          </ContainerTabs>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}

export default WithdrawPage;
