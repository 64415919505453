import points from "assets/points.svg";
import eyes_secondary from "assets/eyes-secondary.svg";

import { LinkWithHover } from "styles/LinkWithHover";
import { Event } from "../styles";
import { useState } from "react";
import { PurchaseType } from "types";
import { Dropdown, DropDownContainer, LinkStyledLiLast } from "styles/Dropdown";
import {
  returnStatusColorPurchase,
  returnStatusPurchase,
} from "utils/purchase";
import { formatPrice } from "utils/formatPrice";
import { StatusComponent } from "components/Status";

type TicketPurchaseProps = {
  purchase: PurchaseType;
  index: number;
};

function TicketPurchase({ purchase, index }: TicketPurchaseProps) {
  const [activeIndexTicket, setActiveIndexTicket] = useState<number | null>(
    null
  );
  const [activePurchase, setActivePurchase] = useState<PurchaseType | null>();

  const handleActivePurchase = (purchase: PurchaseType, index: number) => {
    if (purchase._id === activePurchase?._id) {
      setActivePurchase(null);
      setActiveIndexTicket(null);
      return;
    }

    setActiveIndexTicket(index);
    setActivePurchase(purchase);
  };

  return (
    <Event>
      <div className="column">
        <head>
          <div>
            <LinkWithHover to={`/sales/${purchase?._id}`}>
              <h4>
                <span>ID da venda:</span> {purchase?._id}
              </h4>
            </LinkWithHover>

            <LinkWithHover
              to={`/users/${purchase?.user_id ? purchase?.user_id?._id : ""}`}
            >
              <h4>
                <span>Nome:</span>{" "}
                {purchase?.user_id ? purchase?.user_id?.name : "Não informado"}
              </h4>
            </LinkWithHover>

            <LinkWithHover
              to={`/events/${purchase?.event_id ? purchase?.event_id._id : ""}`}
            >
              <h4>
                <span>Evento:</span>{" "}
                {purchase?.event_id ? purchase.event_id.name : "Não informado"}
              </h4>
            </LinkWithHover>

            <h4>
              <span>Valor:</span> {formatPrice(purchase.total / 100)}
            </h4>
          </div>

          <div className="options">
            <StatusComponent
              content={returnStatusPurchase(purchase.purchase_status)}
              color={returnStatusColorPurchase(purchase?.purchase_status)}
            />

            <DropDownContainer>
              <div
                className="area"
                onClick={() => handleActivePurchase(purchase, index)}
              >
                <img src={points} alt="" />
              </div>

              {activePurchase?._id === purchase?._id &&
              activeIndexTicket === index ? (
                <Dropdown height="65px">
                  <ul>
                    <LinkStyledLiLast
                      className="last"
                      to={`/sales/${purchase?._id}`}
                    >
                      <img src={eyes_secondary} alt="eye" />
                      <h5>Visualizar Venda</h5>
                    </LinkStyledLiLast>
                  </ul>
                </Dropdown>
              ) : null}
            </DropDownContainer>
          </div>
        </head>
      </div>
    </Event>
  );
}

export default TicketPurchase;
