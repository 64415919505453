import Datas from "constants/Datas";

export const SIMPLE_FILTERS = [
  {
    value: "ID",
    key: "_id",
    label: "Buscar por ID",
  },
  {
    value: "Evento",
    key: "organizer_document",
    label: "CPF/CNPJ",
  },
  {
    value: "Nome",
    key: "name",
    label: "Nome",
  },
  {
    value: "Filtros avançados",
    key: "",
    label: "Filtros avançados",
  },
];

export const ADVANCED_FILTERS = [
  {
    label: "Tipo",
    name: "type_ticket",
    type: "select",
    options: Datas.TypeTicket,
    placeholder: "Escolha o tipo",
  },
  {
    label: "ID",
    type: "input",
    name: "_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "Nome",
    type: "input",
    name: "name",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o nome",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: Datas.StatusTicket,
    placeholder: "Escolha o status",
  },
  {
    label: "Organizador",
    type: "organizer",
  },
  {
    label: "Evento",
    name: "event_id",
    type: "event",
  },
  {
    label: "Estado",
    name: "address_state",
    type: "state",
  },
  {
    label: "Cidade",
    type: "city",
  },
  {
    label: "Transferível",
    name: "transfer",
    type: "select",
    options: Datas.YesOrNot,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Idade",
    name: "age",
    type: "select",
    options: Datas.Ages,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Desconto",
    name: "has_discount",
    type: "select",
    options: Datas.YesOrNot,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Cadastrado entre",
    type: "date",
    gte: "created_gte",
    lte: "created_lte",
  },
];
