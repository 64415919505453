import Datas from "constants/Datas";

export const SIMPLE_FILTERS = [
  {
    value: "ID",
    key: "_id",
    label: "Buscar por ID",
  },
  {
    value: "ID do Organizador",
    key: "organizer_id",
    label: "Buscar por ID do organizador",
  },
  {
    value: "Filtros avançados",
    key: "",
    label: "Filtros avançados",
  },
];

export const ADVANCED_FILTERS = [
  {
    label: "ID",
    type: "input",
    name: "_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "ID do Organizador",
    type: "input",
    name: "organizer_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "Saque de antecipação?",
    name: "antecipated",
    type: "select",
    options: Datas.YesOrNotBool,
    placeholder: "Escolha a opção",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: Datas.WithdrawStatuses,
    placeholder: "Escolha o status",
  },
];
