import styled from "styled-components";
import { Colors } from "../../constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItemsTable = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  padding: 0px 60px 0px 60px;
  margin-top: 54px;
  margin-bottom: 100px;

  @media (max-width: 740px) {
    margin-top: 32px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    color: ${Colors.secondary90};
  }

  h3.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-bottom: 0px;
    margin-top: 48px;

    @media (max-width: 740px) {
      padding-left: 34px;
      margin-bottom: 24px;
    }
  }

  div.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding-right: 20px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1240px) {
    padding: 24px 16px 0px 28px;
  }

  @media (max-width: 768px) {
    padding: 0px 0px 0px 0px;
    margin-bottom: 40px;
  }

  @media (max-width: 621px) {
    padding: 30px 0px 0px 0px;
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 49%;
  display: flex;
  flex-direction: column;
  min-height: 60px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    cursor: ${(props) => (props.isEditing ? "normal" : "not-allowed")};
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;
