import { useCallback, useState } from "react";
import {
  ButtonEdit,
  Container,
  ContainerDescription,
  ContainerInputFull,
  FormFull,
  FormLabel,
} from "./styles";
import { Radio, Spin } from "antd";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import {
  handleApproveWithdraw,
  handleRejectWithdraw,
} from "services/hooks/organizer";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import { useSWRConfig } from "swr";

interface WithdrawApprovalProps {
  withdrawId: string;
  onStatusChange: () => void;
}

interface WithdrawApprovalFormData {
  type: string;
  status_detail: string;
}

const formSchema = yup.object().shape({
  type: yup.string().oneOf(["approve", "cancel"]).required("Tipo obrigatório"),
  status_detail: yup
    .string()
    .when("type", {
      is: "approve",
      then: yup.string().notRequired(),
    })
    .when("type", {
      is: "cancel",
      then: yup.string().required("Motivo da reprovação obrigatório"),
    }),
});

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
);

function WithdrawApproval({
  withdrawId,
  onStatusChange,
}: WithdrawApprovalProps) {
  const [fetching, setFetching] = useState(false);
  const {
    control,
    watch,
    register,
    formState: { errors },
    setError,
    handleSubmit,
    clearErrors,
  } = useForm<WithdrawApprovalFormData>({
    resolver: yupResolver(formSchema),
  });
  const typeWatch = watch("type");
  const showReasonInput = typeWatch === "cancel";
  const { mutate } = useSWRConfig();

  const onSubmit = async (
    { type, status_detail }: WithdrawApprovalFormData,
    e: any
  ) => {
    e!.preventDefault();

    if (type === "cancel" && !status_detail) {
      setError("status_detail", { message: "Informe o motivo" });

      return;
    }

    clearErrors("status_detail");

    setFetching(true);

    if (type === "cancel") {
      try {
        await handleRejectWithdraw(withdrawId, status_detail);

        successNotification("Saque reprovado com sucesso.");
      } catch (err: any) {
        errorNotification(
          JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
        );
      }
    }

    if (type === "approve") {
      try {
        await handleApproveWithdraw(withdrawId);

        successNotification("Saque aprovado com sucesso.");
      } catch (err: any) {
        errorNotification(
          JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
        );
      }
    }

    setFetching(false);
    mutate("/backoffice/organizer-withdraw/filter");
    onStatusChange();
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <ContainerDescription>
        <h4>Deseja aprovar o saque?</h4>
      </ContainerDescription>

      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="approve">Aprovar</Radio>
            <Radio value="cancel">Reprovar</Radio>
          </Radio.Group>
        )}
      />

      {showReasonInput && (
        <FormFull>
          <ContainerInputFull isEditing>
            <FormLabel>Informe o motivo da reprovação</FormLabel>

            <input type="text" {...register("status_detail")} />

            {errors.status_detail ? (
              <span className="error">{errors?.status_detail?.message}</span>
            ) : null}
          </ContainerInputFull>
        </FormFull>
      )}

      <ButtonEdit type="submit" isLoading={false}>
        <Spin spinning={fetching} indicator={antIcon} />

        <span>Confirmar</span>
      </ButtonEdit>
    </Container>
  );
}

export default WithdrawApproval;
