export const categoryFaq = (value: string) => {
  switch (value) {
    case "1":
      return "Compras";
    case "2":
      return "Pagamento";
    case "3":
      return "Reembolso";
    case "4":
      return "Ingressos";
    case "5":
      return "Eventos";
    case "6":
      return "Minha conta";

    default:
      return "Compras";
  }
};
