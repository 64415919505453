import { Sidebar } from "components/Sidebar";
import { Container, ContainerItemsTable, Wrapper } from "./styles";
import { Header } from "components/Header";
import { useAuthOrganizerWithdraw } from "services/hooks/organizer";
import { Table } from "./components/Table";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { paramsToString } from "utils/searchOptions";
import useDebounce from "hooks/useDebounce";
import { PaginationFilterComponent } from "components/PaginationFilter";

function WithdrawalPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data } = useAuthOrganizerWithdraw({ filters: debouncedSearch, page });

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },

    [searchParams, setSearchParams]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, searchParams, setSearchParams]);

  const filterAdvancedStatus = searchParams.get("filter_advanced_status");

  const idFilter = searchParams.get("_id") ?? "";
  const organizer_id = searchParams.get("organizer_id") ?? "";
  const antecipated = searchParams.get("antecipated") ?? "";
  const status = searchParams.get("status") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "1") {
      const paramsString = new URLSearchParams(
        paramsToString({
          _id: idFilter,
          organizer_id,
          antecipated,
          status,
        })
      );

      setParamsString(paramsString);
    }
  }, [filterAdvancedStatus, idFilter, organizer_id, antecipated, status]);

  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "0") {
      const paramsString = new URLSearchParams(
        paramsToString({
          simple_filter_id: filterId,
          simple_filter_text: filterText,
        })
      );

      setParamsString(paramsString);
    }
  }, [filterAdvancedStatus, filterId, filterText]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />

        <ContainerItemsTable>
          <h1>Saques</h1>
          <h3 className="title">Solicitações</h3>

          <Table data={data?.results} total={data?.count ?? 0} />

          <div className="pagination">
            <PaginationFilterComponent
              current={page}
              onChange={onChange}
              total={data?.count ?? 0}
            />
          </div>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}

export default WithdrawalPage;
