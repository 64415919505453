import { useState } from "react";

import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { WithdrawItem } from "types";

import { Colors } from "constants/colors";
import { ADVANCED_FILTERS, SIMPLE_FILTERS } from "constants/filters/withdraw";

import {
  Container,
  Card,
  Tr,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  LinkStyled,
  LinkStyledMobile,
} from "./styles";

import { StatusComponent } from "components/Status";
import { HeaderFilterComponent } from "components/HeaderFilter";
import { formatPrice } from "utils/formatPrice";

interface TableProps {
  data?: WithdrawItem[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activeWithdraw, setActiveWithdraw] = useState<WithdrawItem | null>(
    null
  );

  const handleSelectWithdraw = (
    withdrawItem: WithdrawItem,
    active: boolean
  ) => {
    if (active) {
      setActiveWithdraw(withdrawItem);

      return;
    }

    setActiveWithdraw(null);
  };

  const handleRenderStatus = (status: WithdrawItem["status"]) => {
    switch (status) {
      case "pending":
      default:
        return (
          <StatusComponent color="yellow" content="Pendente" size="small" />
        );
      case "canceled":
        return <StatusComponent color="red" content="Cancelado" size="small" />;
      case "process":
        return (
          <StatusComponent
            color="darkBlue"
            content="Processando"
            size="small"
          />
        );
      case "paid":
        return (
          <StatusComponent color="green" content="Aprovado" size="small" />
        );
    }
  };

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          total={total ?? 0}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          label="do saque"
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ORGANIZADOR</th>
                <th>ANTECIPAÇÃO</th>
                <th className="item-show">TOTAL</th>
                <th>TOTAL LÍQUIDO</th>
                <th>TAXAS</th>
                <th>STATUS</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data
                ? Array.from({ length: 20 }).map((_) => (
                    <Tr active={false}>
                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>
                    </Tr>
                  ))
                : null}

              {data &&
                data?.map((withdraw, i) => (
                  <Tr key={i} active={withdraw?._id === activeWithdraw?._id}>
                    <td>
                      <div className="all">
                        <Link to={`/organizers/${withdraw?.organizer?.id}`}>
                          <strong>
                            {withdraw?.organizer?.name ?? "Não informado"}
                          </strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{withdraw?.antecipated ? "Sim" : "Não"}</strong>
                      </div>
                    </td>

                    <td className="item-show">
                      <div className="all">
                        <strong>{formatPrice(withdraw?.total / 100)}</strong>
                      </div>
                    </td>

                    <td className="item-show">
                      <div className="all">
                        <strong>
                          {formatPrice(withdraw?.total_wet / 100)}
                        </strong>
                      </div>
                    </td>

                    <td className="item-show">
                      <div className="all">
                        <strong>
                          {formatPrice(withdraw?.total_fee / 100)}
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        {handleRenderStatus(withdraw?.status)}
                      </div>
                    </td>
                    <td>
                      <div className="all">
                        <div className="column">
                          {activeWithdraw?._id === withdraw?._id ? (
                            <FiChevronUp
                              onClick={() =>
                                handleSelectWithdraw(withdraw, false)
                              }
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          ) : (
                            <FiChevronDown
                              onClick={() =>
                                handleSelectWithdraw(withdraw, true)
                              }
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          )}

                          {activeWithdraw?._id === withdraw?._id ? (
                            <LinkStyled to={`/withdrawal/${withdraw?._id}`}>
                              <span>Detalhes</span>
                            </LinkStyled>
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </Tr>
                ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhuma solicitação de saque encontrada</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            total={total ?? 0}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            label="do saque"
          />

          <List>
            {data &&
              data?.map((withdraw) => (
                <Item>
                  <Link to={`/organizers/${withdraw?.organizer?.id}`}>
                    <h1 className="first">Organizador:</h1>
                    <p className="text">
                      {withdraw?.organizer?.name ?? "Não informado"}
                    </p>
                  </Link>

                  <div>
                    <h2>Antecipação?</h2>

                    <p className="text">
                      {withdraw?.antecipated ? "Sim" : "Não"}
                    </p>
                  </div>

                  <span className="text">
                    Total:&nbsp;
                    {formatPrice(withdraw?.total / 100)}
                  </span>

                  <span className="text">
                    Total líquido:&nbsp;
                    {formatPrice(withdraw?.total_wet / 100)}
                  </span>

                  <span className="text">
                    Taxas:&nbsp;
                    {formatPrice(withdraw?.total_fee / 100)}
                  </span>

                  <div className="chevron-mobile">
                    <div>
                      <span className="text">Status</span>
                      {handleRenderStatus(withdraw?.status)}
                    </div>

                    {activeWithdraw?._id === withdraw?._id ? (
                      <FiChevronUp
                        onClick={() => handleSelectWithdraw(withdraw, false)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    ) : (
                      <FiChevronDown
                        onClick={() => handleSelectWithdraw(withdraw, true)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    )}
                  </div>

                  {activeWithdraw?._id === withdraw?._id ? (
                    <div className="footer-details">
                      <LinkStyledMobile to={`/withdrawal/${withdraw?._id}`}>
                        <span>Detalhes</span>
                      </LinkStyledMobile>
                    </div>
                  ) : null}
                </Item>
              ))}

            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data && data.length === 0 ? (
              <div className="container-empty">
                <h3>Nenhum Usuário Encontrado</h3>
              </div>
            ) : null}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
