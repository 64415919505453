import { useState } from "react";

import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { OrganizerType } from "types";
import { Colors } from "constants/colors";
import { SIMPLE_FILTERS, ADVANCED_FILTERS } from "constants/filters/organizers";
import { ADMIN_STATUS, ADMIN_STATUS_COLORS } from "constants/adminStatus";

import {
  Container,
  Card,
  Tr,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  LinkStyled,
  LinkStyledMobile,
  TrExtra,
  TrBody,
} from "./styles";

import { StatusComponent } from "components/Status";
import { HeaderFilterComponent } from "components/HeaderFilter";
import { SkeletonRowTable } from "components/SkeletonRowTable";
import {
  maskCpfOrCnpj,
  phoneNormal,
  phoneToString,
  removeSpecialCharacters,
} from "utils/masks";

interface TableProps {
  data?: OrganizerType[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activeOrganizer, setActiveOrganizer] = useState<OrganizerType | null>(
    null
  );

  function handleSelectedOrganizer(organizer: OrganizerType, active: boolean) {
    if (active) {
      setActiveOrganizer(organizer);

      return;
    }

    setActiveOrganizer(null);
  }

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          total={total ?? 0}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          label="do organizador"
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>NOME</th>
                <th>CPF/CNPJ</th>
                <th>EVENTOS</th>
                <th>STATUS</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!data ? (
                <SkeletonRowTable
                  height_size={30}
                  width_size={60}
                  row_numbers_size={10}
                  column_numbers_size={7}
                />
              ) : null}

              {data?.map((organizer, i) => (
                <>
                  <Tr
                    className="main"
                    key={i}
                    active={organizer?._id === activeOrganizer?._id}
                  >
                    <td>
                      <div className="all">
                        <Link to={`/organizers/${organizer?._id}`}>
                          <strong>{organizer?._id}</strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{organizer?.name ?? "Não informado"}</strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <Link to={`/organizers/${organizer?.id}`}>
                          <strong>
                            {maskCpfOrCnpj(
                              removeSpecialCharacters(organizer?.agent_document)
                            )}
                          </strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>
                          {organizer?.events ? organizer?.events.length : 0}
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="status-wrapper">
                        <div className="all">
                          <strong>Status organizador</strong>
                          <StatusComponent
                            content={organizer.status ? "Ativo" : "Inativo"}
                            color={organizer.status ? "green" : "red"}
                          />
                        </div>

                        {!!organizer?.admin_status && (
                          <div className="all">
                            <strong>Status administrador</strong>
                            <StatusComponent
                              content={
                                ADMIN_STATUS.find(
                                  (i) =>
                                    i.value ===
                                    organizer?.admin_status.toLocaleLowerCase()
                                )?.label ?? ""
                              }
                              color={
                                ADMIN_STATUS_COLORS.find(
                                  (i) =>
                                    i.value ===
                                    organizer?.admin_status.toLocaleLowerCase()
                                )?.color ?? ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>

                    <td></td>
                    <td></td>

                    <td>
                      <div className="all">
                        <div className="column">
                          {activeOrganizer?._id === organizer._id ? (
                            <FiChevronUp
                              onClick={() =>
                                handleSelectedOrganizer(organizer, false)
                              }
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          ) : (
                            <FiChevronDown
                              onClick={() =>
                                handleSelectedOrganizer(organizer, true)
                              }
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </Tr>

                  {activeOrganizer?._id === organizer._id ? (
                    <TrExtra>
                      <th>USUÁRIO</th>
                      <th>ORGANIZADOR</th>
                      <th>RESPONSÁVEL</th>
                      <th>TAXA</th>
                      <th>ESTADO / CIDADE</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </TrExtra>
                  ) : null}

                  {activeOrganizer?._id === organizer._id ? (
                    <TrBody key={i} active>
                      <td>
                        <div className="column-one">
                          <strong className="first">
                            <Link to={`/users/${organizer?.user._id}`}>
                              <b>{organizer?.user.name ?? "Não informado"}</b>
                            </Link>
                          </strong>
                          <strong>
                            {organizer?.user.cell_phone ?? "Não informado"}
                          </strong>
                          <strong className="final">
                            {organizer?.user.email
                              ? organizer?.user.email.length > 22
                                ? `${organizer?.user.email.substring(0, 22)}...`
                                : organizer?.user.email
                              : ""}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            <b>{organizer?.document_name ?? "Não informado"}</b>
                          </strong>
                          <strong>
                            {organizer?.business_phone
                              ? phoneNormal(organizer.business_phone)
                              : "Não informado"}
                          </strong>
                          <strong className="final">
                            {organizer?.business_email
                              ? organizer?.business_email.length > 22
                                ? `${organizer?.business_email.substring(
                                    0,
                                    22
                                  )}...`
                                : organizer?.business_email
                              : ""}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            <b>{organizer?.agent_name ?? "Não informado"}</b>
                          </strong>
                          <strong>
                            {organizer?.agent_document
                              ? maskCpfOrCnpj(organizer.agent_document)
                              : "Não informado"}
                          </strong>
                          <strong>
                            {organizer?.agent_phone
                              ? phoneNormal(organizer.agent_phone)
                              : "Não informado"}
                          </strong>
                          <strong className="final">
                            {organizer?.agent_email
                              ? organizer?.agent_email.length > 22
                                ? `${organizer?.agent_email.substring(
                                    0,
                                    22
                                  )}...`
                                : organizer?.agent_email
                              : ""}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            <b>{organizer?.fee ?? "Não informado "}%</b>
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            {organizer?.address_city ?? "Cidade não informada "}{" "}
                            -{" "}
                            {organizer?.address_state ??
                              "Estado não informado "}
                          </strong>
                        </div>
                      </td>

                      <td></td>

                      <td></td>

                      <td>
                        <div className="column">
                          <LinkStyled to={`/organizers/${organizer._id}`}>
                            <span>Detalhes</span>
                          </LinkStyled>
                        </div>
                      </td>
                    </TrBody>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhum Organizador Encontrado</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            total={total ?? 0}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            label="do organizador"
          />

          <List>
            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data && data.length === 0 ? (
              <div className="container-empty">
                <h3>Nenhum Organizador Encontrado</h3>
              </div>
            ) : null}

            {data &&
              data?.map((organizer) => (
                <Item>
                  <Link to={`/organizers/${organizer._id}`}>
                    <h1 className="first">
                      {organizer?.name ?? "Não informado"}
                    </h1>
                  </Link>

                  <h2>
                    {maskCpfOrCnpj(
                      removeSpecialCharacters(organizer.agent_document)
                    )}
                  </h2>

                  <p>{organizer.agent_email}</p>

                  <span className="text">
                    {organizer.agent_phone ?? "Não informado"}
                  </span>

                  <span className="text-last">
                    Eventos totais:{" "}
                    {organizer.events ? organizer.events.length : 0}
                  </span>

                  <div className="chevron-mobile">
                    <StatusComponent
                      content={organizer.status ? "ATIVO" : "INATIVO"}
                      color={organizer.status ? "green" : "red"}
                    />

                    {activeOrganizer?._id === organizer._id ? (
                      <FiChevronUp
                        onClick={() =>
                          handleSelectedOrganizer(organizer, false)
                        }
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    ) : (
                      <FiChevronDown
                        onClick={() => handleSelectedOrganizer(organizer, true)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    )}
                  </div>

                  {activeOrganizer?._id === organizer._id ? (
                    <div className="footer-details">
                      <LinkStyledMobile to={`/organizers/${organizer._id}`}>
                        <span>Detalhes</span>
                      </LinkStyledMobile>
                    </div>
                  ) : null}
                </Item>
              ))}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
